import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;
  constructor(private formBuilder: FormBuilder,private authService:AuthService, 
       public iziToast: Ng2IzitoastService,private router: Router, private tokenStorage :TokenStorageService,


  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
  });

  }
  get f() { return this.registerForm.controls; }



  onSubmit() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
    this.authService.login(this.registerForm.value).subscribe(data => {
      console.log("data",data)
     
      if(data["success"]){
        localStorage.setItem('token',data["token"])
        localStorage.setItem('user',data["user"])
        this.router.navigate(['/profil' ])
        this.tokenStorage.saveUser(data);
     
      } 
      else{
        this.iziToast.show({
          message:"Merci de bien vérifier vos informations.",
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          //overlay:true,
          overlayClose:true,	
         });
      }
    })
    
      
    
  }



ngAfterViewInit(): void {
    
  window.scrollTo(0, 0);
}
}
