// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //baseUrl:  `http://localhost:3031`,
  uploadFileRoot : '/images',

  baseUrl:  `/api`,
  recaptcha: {
    //siteKey: '6Lf7UL0cAAAAAIt_m-d24WG4mA1XFPHE8yVckc5S',
    
    siteKey: '6Lc8yYQpAAAAACtIA9gJ8mHlItNTwRCa0NcmaXxm',
    secretKey :'6Lc8yYQpAAAAAOCl1XQXaGnULDzPlkhiVIOTR2S5',
  },
  urlBackoffice : " /"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
