import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';


@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {
  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  registerForm :FormGroup
  submitted: boolean = false;



  authService: AuthService;
  user
  userInitial: any;
  // urlBackOffice = environment.urlBackoffice
  constructor( private authServiceInstance: AuthService,private utilisateurService :UtilisateurService,
    private formBuilder: FormBuilder,private router: Router,public iziToast: Ng2IzitoastService,


  ) {
    this.authService = authServiceInstance;

   }

  ngOnInit(): void {
   
    if(this.authService.loggedIn()){
      let currentUser = localStorage.getItem('user')
      this.utilisateurService.getUserrById(currentUser).subscribe(async data=>{
        this.user=data
        this.userInitial =this.user
       console.log(" this.user", this.user)
       this.registerForm = this.formBuilder.group({
          
        nom: ['', Validators.required],
        prenom: ['', Validators.required],
        email: ['', ],
        tel: ['', Validators.required],
        societe: ['', Validators.required],
        numeroEntreprise: ['', Validators.required],
    
        
    },);

      })
    }
  }
  onSubmit() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
    console.log("this.registerForm",this.registerForm.value)
    if(this.registerForm.value.tel){
      this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber 
    }
    this.utilisateurService.updateProfile(this.user._id,this.registerForm.value).subscribe(
      (data) => {
       console.log("waa")
        
        this.iziToast.show({
          message:'Mise à jour de profil avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
          })
  }
  cancel(){
    console.log("this.userInitial",this.userInitial)
    this.utilisateurService.getUserrById(localStorage.getItem('user')).subscribe(async data=>{
      this.user=data
    })
  }
  get f() { return this.registerForm.controls; }


}
