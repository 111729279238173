import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(private injector :Injector) { }

  intercept(req ,next ) {
    let authenticationService = this.injector.get(AuthService)
    let tokenzedReq =req.clone({
      setHeaders: {
        Authorization : `Bearer ${authenticationService.getToken()}`
      }
    })
    return next.handle(tokenzedReq)
}

}
