import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ConnexionComponent } from './components/connexion/connexion.component';
import { ProductsComponent } from './components/products/products.component';
import { MotDePasseOublieComponent } from './components/mot-de-passe-oublie/mot-de-passe-oublie.component';
import { ProfilComponent } from './components/profil/profil.component';
import { AuthGuard } from './_guards/auth.guard';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { InscriptionComponent } from './components/inscription/inscription.component';
const routes: Routes = [
  
  { path: '', component: HomeComponent },
  { path: 'inscription', component: InscriptionComponent },
  { path: 'connexion', component: ConnexionComponent },
  { path: 'mot-de-passe-oublie', component: MotDePasseOublieComponent },
  { path: 'produits', component:ProductsComponent},
  { path: 'detail-produit', component:ProductDetailsComponent},
  { path: 'profil', component:ProfilComponent,canActivate:[AuthGuard]},


  
 
  



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
