import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InscriptionComponent } from './components/inscription/inscription.component';
import { ConnexionComponent } from './components/connexion/connexion.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CookiesComponent } from './shared/cookies/cookies.component';
import { CookieService } from 'ngx-cookie-service';
import { Ng2IziToastModule } from "ng2-izitoast";
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProductsComponent } from './components/products/products.component';
import { MotDePasseOublieComponent } from './components/mot-de-passe-oublie/mot-de-passe-oublie.component';
import { ProfilComponent } from './components/profil/profil.component';
import { AuthGuard } from './_guards/auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { NgCircleProgressModule } from 'ng-circle-progress';




@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    InscriptionComponent,
    ConnexionComponent,
    CookiesComponent,
    ProductsComponent,
    MotDePasseOublieComponent,
    ProfilComponent,
    ProductDetailsComponent
  
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    Ng2IziToastModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    HttpClientModule,
    NgxIntlTelInputModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule . forRoot ( ) ,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#e6b31e",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      
    })
    
    
  ],
  providers: [AuthGuard,{
    provide :HTTP_INTERCEPTORS,
    useClass :TokenInterceptorService,
    multi:true
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
