import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ContactMarketingService } from 'src/app/services/contact-marketing.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MedecinService } from 'src/app/services/medecin.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/services/message.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  products: any;
  pathFile = environment.uploadFileRoot ;
  country='BE'
  countryCode="32";
  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  selectedOption: string = '';

  @ViewChild('selectMedecin') selectMedecin: ElementRef;

  
  @ViewChild('exampleModalArchiver') modal: any;
  registerForm2 :FormGroup
  submitted2: boolean;
  tokenGenetate: string;

  authService: AuthService;

  initialProductsCount: number = 6; // Nombre initial de produits à afficher
  displayedProducts: any[] = [];
  copyslistProducts: any;
  productsPromo: any;

  constructor(
    private meta:Meta,
    private title: Title ,
    private formBuilder: FormBuilder,
    private messageService :MessageService,
    private router: Router,
    public iziToast: Ng2IzitoastService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private productService :ProductService,
    private authServiceInstance: AuthService,
    private sharedService :SharedService

  )
  {
    this.authService = authServiceInstance;
    this.meta.updateTag(
      {name:"description", content:"Découvrez notre plateforme de e-learning interactive qui vous offre un accès à une multitude de cours en ligne. Apprenez à votre rythme, acquérez de nouvelles compétences et développez votre potentiel avec notre plateforme d'apprentissage en ligne."}
      
    )
    this.setTitle("Time To Be"

    )
  }
  setTitle(newtille){
    this.title.setTitle(newtille)
  }


  ngOnInit(): void {

  this.registerForm2 = this.formBuilder.group({
          
    nom: ['', Validators.required],
    prenom: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telephone: ['', Validators.required],
    message: ['', [Validators.required, Validators.maxLength(200)]],
    token : ['',]

    
},);
this.getProducts()
this.getProductsOutlet()

  }
  get f2() { return this.registerForm2.controls; }

  getProducts() {
    this.productService.getListProductsStorm().subscribe((e) => {
      this.products = e.reverse();
      console.log("products",this.products)
      // Sélectionner les 3 derniers éléments
      this.products = this.products.slice(0, 4);


    });
  
}

getProductsOutlet() {
  this.productService.getproductsOutlet().subscribe((e) => {
    this.productsPromo = e
    this.copyslistProducts = this.productsPromo
    //console.log("products",this.products)
    // Sélectionner les 3 derniers éléments
    //this.products = this.products.slice(0, 4);
    this.displayedProducts = this.copyslistProducts.slice(0, this.initialProductsCount);


  });

}




zoomIn(event: any): void {
  event.target.style.transform = 'scale(1.2)';
}

zoomOut(event: any): void {
  event.target.style.transform = 'scale(1)';
}

onSubmit2() {
  
  this.submitted2 = true;
  if (this.registerForm2.invalid) {
      return;
  }
  this.registerForm2.value.telephone = this.registerForm2.value.telephone.internationalNumber
  this.tokenGenetate = localStorage.getItem('reCAPTCHAToken')
  this.registerForm2.value.token = this.tokenGenetate

  this.messageService.createMessage(this.registerForm2.value).subscribe(
    (data) => {
      // if (this.modal) {
      //   this.modal.nativeElement.querySelector('.close').click();
      // }
      //this.verifyRecaptcha()
      this.onReset2()
      this.iziToast.show({
        message:'Votre message a été bien envoyé!',
        messageColor:'#21726B',
        progressBarColor:'#21726B',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#e7f5f5',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         })

    
    },
    (error) => {
      //this.verifyRecaptcha()
      this.onReset2()
        this.iziToast.show({
          message: 'Une erreur est survenue. Merci de saisir vos données!',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          icon: 'bi-exclamation-diamond',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          // overlay: true,
          // overlayClose: true,
        });
        return;

    }
   
  );

}
onReset2() {
this.submitted2 = false;
this.registerForm2.reset();
}










verifyRecaptcha() {
  this.recaptchaV3Service.execute(environment.recaptcha.siteKey)
    .subscribe((token: string) => {
      localStorage.setItem('reCAPTCHAToken',token)
      //this.sendTokenToBackend(token);

      // Envoyer le token au backend pour la vérification
    }, (error: any) => {
      console.error('reCAPTCHA error:', error);
    });
}



openSelect() {
  this.selectMedecin.nativeElement.style.display = 'block';
}

closeSelect() {
  this.selectMedecin.nativeElement.style.display = 'none';
}

goToDetails(id,nom){
  this.router.navigate(["detail-produit"]);
  this.sharedService.saveInfoProduct(id,nom)
}

loadMoreProducts() {
  this.displayedProducts = this.productsPromo
}
loadLessProducts() {
  this.displayedProducts = this.productsPromo.slice(0, this.initialProductsCount);
}
}


