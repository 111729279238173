import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _authtURL=`${environment.baseUrl}/authenticate`

  constructor(private http: HttpClient,private tokenStorageService :TokenStorageService,private router :Router) { }

  login(user: any):Observable<Object> {
    return this.http.post(`${this._authtURL}/utilisateur`,user);
  }

  getToken()
  {
    return localStorage.getItem('token')
  }
  
  loggedIn()
  {
    return !!localStorage.getItem('token')
  }
  
  
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.tokenStorageService.removeUser();
    this.router.navigate(['/connexion'])
  
    
  }

     
}
