import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as bootstrap from 'bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  authService: AuthService;

  verif = "background-transparent";
  user
  // urlBackOffice = environment.urlBackoffice
  constructor(private router: Router, private authServiceInstance: AuthService,private utilisateurService :UtilisateurService) {
    this.authService = authServiceInstance;

   }

  ngOnInit(): void {
    this.router.url !== "/" ? (this.verif = "") : (this.verif = "background-transparent ");
    if(this.authService.loggedIn()){
      let currentUser = localStorage.getItem('user')
      this.utilisateurService.getUserrById(currentUser).subscribe(async data=>{
        this.user=data
       console.log(" this.user", this.user)

      })
    }
  }

ngAfterViewInit(): void {
  const navbarShrink = () => {
    const navbarCollapsible = document.body.querySelector('#mainNav');
    if (!navbarCollapsible) {
      return;
    }
    if (window.scrollY === 0) {
      navbarCollapsible.classList.remove('navbar-shrink');
    } else {
      navbarCollapsible.classList.add('navbar-shrink');
    }
  };

  // Shrink the navbar 
  navbarShrink();

  // Shrink the navbar when page is scrolled
  document.addEventListener('scroll', navbarShrink);

  // Activate Bootstrap scrollspy on the main nav element
  const mainNav = document.body.querySelector('#mainNav');
  if (mainNav) {
    new bootstrap.ScrollSpy(document.body, {
      target: '#mainNav',
      rootMargin: '0px 0px -40%',
    });
  }

 // Collapse responsive navbar when menu item is clicked
 const responsiveNavItems = document.querySelectorAll('#navbarResponsive .nav-link');

 responsiveNavItems.forEach((responsiveNavItem: HTMLElement) => {
   responsiveNavItem.addEventListener('click', () => {
     const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;
     const bootstrapCollapse = new bootstrap.Collapse(navbarCollapse);
     bootstrapCollapse.hide();
   });
 });
}
logout(){
  this.authService.logout();
}

}
