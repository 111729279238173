import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
productInfo
product;
pathFile = environment.uploadFileRoot ;
authService: AuthService;
  promo: string;
  subtitle: string = 'Promo!';

  constructor(private sharedService :SharedService,private productService :ProductService,
    private router :Router,private authServiceInstance: AuthService

  ) { 
    this.authService = authServiceInstance;

  }

  ngOnInit(): void {
    this.productInfo = this.sharedService.getInfoProduct();
    if(!this.productInfo["id"]){
      this.router.navigate(["produits"]);
    }
    else{
    this.productService.getDetailsProduct(this.productInfo["id"]).subscribe((e) => {
      this.product = e
      console.log("this.product", this.product);
      this.promo = this.product.promo
    })
  }
}
  

}
