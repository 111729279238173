<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="slideshow">
        <img src="../../../assets/img/DSC_0925.webp" alt="Image 1">
        <img src="../../../assets/img/DSC_0915.webp" alt="Image 2">
        <img src="../../../assets/img/DSC_0942.webp" alt="Image 3">
    </div>
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <h1 class="mx-auto my-0">Profil</h1>
            </div>
        </div>
    </div>
</header>
<section class="projects-section bg-light" id="products" style="padding: 0 !important;">
    <div class="p-5">
        <div class="row">
            <!-- Première carte -->
            <div class="col-lg-4">
                <div *ngIf="user" class="card p-2">
                    <div class="card-body text-center">
                        <!-- Image dans un cercle -->
                        <div class="rounded-circle mx-auto mb-3" style="width: 175px; height: 200px">
                            <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="Image de profil" class="img-fluid">
                        </div>
                        <!-- Nom, prénom et société -->
                        <h5 class="card-title" style="color: #cacaca;">{{user.nom}} {{user.prenom}}</h5>
                        <h5 class="card-title" style="color:#e6b31e;"><strong>{{user.societe}}</strong></h5>
                    </div>
                </div>
            </div>            
            <!-- Deuxième carte -->
            <div class="col-lg-8">
                <div *ngIf="user" class="card p-2">
                    <div class="card-body">
                        <h5 class="card-title text-center" style="color:#e6b31e;"><strong>Informations
                                Générales</strong></h5>
                        <br>
                        <div class="row">
                            <div class="col-sm-3">
                                <label class="label-text">Dénomination:</label>
                            </div>
                            <div class="col-sm-9">
                                <p class="card-text" style="color: #cacaca;">{{user.societe}}</p>
                            </div>
                        </div>
                        <hr style="color: #cacaca;">
                        <div class="row">
                            <div class="col-sm-3">
                                <label class="label-text">Numéro d'entreprise:</label>
                            </div>
                            <div class="col-sm-9">
                                <p class="card-text" style="color: #cacaca;">{{user.numeroEntreprise}}</p>
                            </div>
                        </div>
                        <hr style="color: #cacaca;">
                        <div class="row">
                            <div class="col-sm-3">
                                <label class="label-text">Email:</label>
                            </div>
                            <div class="col-sm-9">
                                <p class="card-text" style="color: #cacaca;">{{userInitial.email}}</p>
                            </div>
                        </div>
                        <hr style="color: #cacaca;">
                        <div class="row">
                            <div class="col-sm-3">
                                <label class="label-text">Téléphone:</label>
                            </div>
                            <div class="col-sm-9">
                                <p class="card-text" style="color: #cacaca;">{{user.tel.number}}</p>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-sm-3">
                                <label class="label-text">Adresse:</label>
                            </div>
                            <div class="col-sm-9">
                                <p class="card-text">Adresse</p>
                            </div>
                        </div> -->
                        <br>
                        <div class="row justify-content-center">
                            <div class="col-sm-6 col-md-3 mb-3">
                                <button type="button" class="btn btn-connexion btn-block">
                                    Mot de passe
                                    <i class="fas fa-edit mr-3"></i>
                                </button>
                            </div>
                            <div class="col-sm-6 col-md-3 mb-3">
                                <button type="button" data-toggle="modal"
                                data-target="#exampleModalMajProfil" class="btn btn-connexion btn-block">
                                Profil
                                <i class="fas fa-edit mr-3"></i>
                              </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-footer></app-footer>


<!--modal-->
<div class="modal fade" id="exampleModalMajProfil" #exampleModalMajProfil tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered custom-modal-width" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel" style="color: #e6b31e">
                    Modifier le profil
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form *ngIf="user" #f00="ngForm" [formGroup]="registerForm"  >
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="firstname">Dénomination <b style="color: red">*</b></label>
                        <input type="text" id="societe" name="societe" class="form-control" formControlName="societe" [(ngModel)]="user.societe"
                          [ngClass]="{ 'is-invalid': submitted && f.societe.errors }" />
                        <div *ngIf="submitted && f.societe.errors" class="invalid-feedback">
                          <div *ngIf="f.societe.errors.required">
                            Ce champ est obligatoire.
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="lastname">Numéro d'entreprise <b style="color: red">*</b></label>
                        <input type="text" id="numeroEntreprise" name="numeroEntreprise" class="form-control" formControlName="numeroEntreprise"  [(ngModel)]="user.numeroEntreprise"
                          [ngClass]="{ 'is-invalid': submitted && f.numeroEntreprise.errors }" />
                        <div *ngIf="submitted && f.numeroEntreprise.errors" class="invalid-feedback">
                          <div *ngIf="f.numeroEntreprise.errors.required">
                            Ce champ est obligatoire.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="firstname">Nom <b style="color: red">*</b></label>
                        <input type="text" id="firstname" name="firstname" class="form-control" formControlName="nom"  [(ngModel)]="user.nom"
                          [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" />
                        <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                          <div *ngIf="f.nom.errors.required">
                            Ce champ est obligatoire.
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="lastname">Prénom <b style="color: red">*</b></label>
                        <input type="text" id="lastname" name="lastname" class="form-control" formControlName="prenom"  [(ngModel)]="user.prenom"
                          [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" />
                        <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                          <div *ngIf="f.prenom.errors.required">
                            Ce champ est obligatoire.
                          </div>
                        </div>
                      </div>
                    </div>
                      <div class="form-group" >
                        <label for="email">Email <b style="color: red">*</b></label>
                        <input type="email" id="email" name="email" class="form-control" formControlName="email" [(ngModel)]="user.email" 
                              />
                      
                    </div>
                    
                      <div class="form-group" >
                        <label for="phone">Numéro de téléphone <b style="color: red">*</b></label>
                        <ngx-intl-tel-input [cssClass]="'form-control input-custom-phone'" formControlName="tel" [(ngModel)]="user.tel"
                          [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                          [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Belgium" [maxLength]="15"
                          [phoneValidation]="true" name="tel"
                          [ngClass]="{ 'is-invalid': submitted && f.tel.errors }"></ngx-intl-tel-input>
                        <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                          <div *ngIf="f.tel.errors.required">Ce champ est obligatoire.</div>
                        </div>
            
                        <div *ngIf="
                              registerForm.value.tel &&
                              !f00.form.controls['tel'].invalid == false
                            " style="color: red">
                          Ce numéro de téléphone est invalide.
                          <i class="bi bi-x"></i>
                        </div>
                        <div *ngIf="
                              registerForm.value.tel &&
                              !f00.form.controls['tel'].invalid == true
                            " style="color: green">
                          Ce numéro de téléphone est valide.
                          <i class="bi bi-check"></i>
                        </div>
                      </div>
                    <div class="modal-footer">
                        <button
                          type="button"
                          style="
                            background: white!important;
                            color: #e6b31e!important;
                            border: 1px solid #e6b31e!important;
                          "
                          class="btn btn-secondary"
                          (click)="cancel()"
                          data-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button 
                        [disabled]="registerForm.invalid"
                        style="
                            background: #e6b31e!important;
                            color: #fff!important;
                        "
                        (click)="onSubmit()"
                        class="btn btn-add"
                        data-dismiss="modal">
                        Confirmer
                        
                    </button>
                    
                      </div>
                  </form>
            </div>
           
        </div>
    </div>
</div>


