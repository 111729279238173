<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="slideshow">
        <img src="../../../assets/img/DSC_0925.webp" alt="Image 1">
        <img src="../../../assets/img/DSC_0915.webp" alt="Image 2">
        <img src="../../../assets/img/DSC_0942.webp" alt="Image 3">
    </div>
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <h1 class="mx-auto my-0">Découvrez nos produits!</h1>
            </div>
        </div>
    </div>
</header>



<section class="projects-section bg-light" id="products" style="padding: 0 !important;">
  
    <div class="p-5">
        <div *ngIf="product" class="container">
            <div class="row">
                <!-- Colonne pour l'image du produit -->
                <div class="col-md-6">
                    <img class="img-fluid" src="{{pathFile}}{{product.images[0].lien}}" alt="Image du produit">
                </div>

                <!-- Colonne pour les informations du produit -->
                <div class="col-md-6 p-2">
                    <!-- Titre du produit -->
                    <h2 style="color: #e6b31e;">{{product.nom}}</h2>

                    <!-- Indicateur de catégorie & statut -->
                    <div class="row align-items-center">
                        <div class="col-6">
                        <p><span class="badge badge-success">{{product.categories.nom}}</span></p>
                        <p style="color: #cacaca;">
                            <span [ngClass]="{'circle-success': product.etat === 'En stock', 'circle-danger': product.etat === 'Rupture de stock', 'circle-warning': product.etat !== 'En stock' && product.etat !== 'Rupture de stock'}"></span>
                            {{product.etat}}
                        </p>
                    </div>
                    <div class="text-center" *ngIf="authService.loggedIn() && product.tarifpromo != ''" class="col-6" style="height: 1px;">
                        <circle-progress
                        [percent]=promo
                        [radius]="100"
                        [outerStrokeWidth]="16"
                        [innerStrokeWidth]="8"
                        [outerStrokeColor]="'#e6b31e'"
                        [innerStrokeColor]="'#ffe69c'"
                        [animation]="false"
                        [animationDuration]="300"
                        [showSubtitle] ="true"
                        [titleColor]="'#e6b31e'"
                        [subtitle]="subtitle"
                        >
                    </circle-progress>
                    </div>
                    </div>
                    
                    
                    
                    <!-- Informations du produit -->
                    <p *ngIf="authService.loggedIn() && product.tarifpromo == '' " style="color: #cacaca;"><strong
                            style="color: #e6b31e;">Prix:</strong> {{product.tarifregulier}}€ </p>
                    <p *ngIf="authService.loggedIn() && product.tarifpromo != ''" style="color: #cacaca;"><strong
                            style="color: #e6b31e;">Prix:</strong> {{product.tarifpromo}}€ </p>        
                    <p style="color: #cacaca;"><strong style="color: #e6b31e;">Conditionnement:</strong>
                        {{product.dimensions.conditionnement}}</p>
                        
                    <p style="color: #cacaca;"><strong style="color: #e6b31e;">Couleur:</strong> {{product.couleur}}</p>
                    <p style="color: #cacaca;"><strong style="color: #e6b31e;">Ratio:</strong> {{product.ratio}}</p>
                    <p style="color: #cacaca;"><strong style="color: #e6b31e;">Nombre de corde:</strong>
                        {{product.nbreCorde}}</p>
                    <p style="color: #cacaca;"><strong style="color: #e6b31e;">Nombre de poche:</strong>
                        {{product.nbrePoche}}</p>
                        <div *ngIf="authService.loggedIn()" class="form-group row align-items-center">
                            <label class=" col-form-label"><strong style="color: #e6b31e;">Quantité:</strong></label>
                            <div class="col-sm-8">
                                <input type="number" class="form-control col-2" id="quantity" min="1" placeholder="1" onkeypress="return /[0-9]/i.test(event.key)">
                            </div>
                        </div>                        
                    <br>
                    <!-- Bouton "Ajouter au panier" -->
                    <div *ngIf="authService.loggedIn()" class="form-group row">
                        <div class="col-sm-12">
                            <button type="button" class="btn btn-connexion"
                                [disabled]="product.etat !== 'En stock'">Ajouter au panier</button>
                        </div>
                    </div>
                
                
                </div>
            </div>
        </div>
    </div>
</section>





<app-footer></app-footer>