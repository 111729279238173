import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
idProduct
nameProduct
  constructor() { }

  saveInfoProduct(id,name){
    this.idProduct = id
    this.nameProduct = name
  }

  getInfoProduct(){
    return { id: this.idProduct, name: this.nameProduct };
  }
  
}
