import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  private _userURL=`${environment.baseUrl}/utilisateur`

  constructor(private http: HttpClient) { }


  verifyEmail(user: any):Observable<Object> {
    return this.http.post(`${this._userURL}/verifiedEmail`,user);
  }


  // inscription utilisateur
  inscriptionUtilisateur(user) {
    return this.http.post(`${this._userURL}/inscription/new/utilisateur`,user);
  }

  // get curent user connected 
  getUserrById(id : string):Observable<any>
  {
    return this.http.get(`${this._userURL}/curent-user/${id}`);

  } 

  updateProfile(id:string,user:any):Observable<Object> {
    return this.http.put(`${this._userURL}/${id}`,user);
  }

}
