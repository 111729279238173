<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div class="container px-4 px-lg-5 d-flex justify-content-between align-items-center">
        <a class="navbar-brand" href="#accueil">
            <img src="../../../assets/img/logo.png" alt="Logo" class="img-fluid" style="max-width: 175px;">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-center">
                <li class="nav-item"><a class="nav-link" href="#accueil">Accueil</a></li>
                <li class="nav-item"><a class="nav-link" href="#presentation">Présentation</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/produits">Produits</a></li>
                <li class="nav-item"><a class="nav-link" href="#outlet">Outlet</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                <!-- <li *ngIf="authService.loggedIn()" class="nav-item"><a class="nav-link" routerLink="/profil">Profil</a></li> -->

            </ul>
            <ul class="navbar-nav d-flex align-items-center">
                <li class="nav-item">
                    <a *ngIf="!authService.loggedIn()" routerLink="/connexion" class="btn btn-connexion">Connexion</a>
                </li>
                <li class="nav-item d-flex align-items-center">
                    <span *ngIf="authService.loggedIn() && user" class="nav-link mr-2" routerLink="/profil" style="cursor: pointer;">
                        <strong><i class="fa-regular fa-circle-user" style="font-size: 1.3rem;"></i> {{user.prenom}} {{user.nom}}</strong></span>
                    <a *ngIf="authService.loggedIn()" class="nav-link"><strong><i class="fa-solid fa-cart-shopping" style="font-size: 1.3rem;"></i> </strong></a>
                    <a *ngIf="authService.loggedIn()" class="nav-link" (click)="logout()"><strong><i class="fa-solid fa-right-from-bracket" style="font-size: 1.3rem;cursor: pointer;"></i> </strong></a>
                </li>
            </ul>
        </div>
    </div>
</nav>
