<!-- <div class="container-fluid py-3">
    <h1>Angular reCAPTCHA v3</h1>
  
    <form #form="ngForm">
      <div class="row mt-3">
        <div class="col-sm-12 mb-2">
          <button type="button" class="btn btn-sm btn-primary" (click)="send(form)">Send</button>
        </div>
      </div>
    </form>
  </div> -->
  <router-outlet></router-outlet>


<!-- <h4>vvv</h4> -->



