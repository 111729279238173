<app-navbar></app-navbar>
<header class="masthead" id="accueil">
  <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
    <div class="card col-lg-12 col-md-10 col-sm-12 p-5">
      <br>
      <form #f00="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <h2 class="text-center">Inscription </h2>
        <br />
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstname">Dénomination <b style="color: red">*</b></label>
            <input type="text" id="societe" name="societe" class="form-control" formControlName="societe"
              [ngClass]="{ 'is-invalid': submitted && f.societe.errors }" />
            <div *ngIf="submitted && f.societe.errors" class="invalid-feedback">
              <div *ngIf="f.societe.errors.required">
                Ce champ est obligatoire.
              </div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="lastname">Numéro d'entreprise <b style="color: red">*</b></label>
            <input type="text" id="numeroEntreprise" name="numeroEntreprise" class="form-control" formControlName="numeroEntreprise"
              [ngClass]="{ 'is-invalid': submitted && f.numeroEntreprise.errors }" />
            <div *ngIf="submitted && f.numeroEntreprise.errors" class="invalid-feedback">
              <div *ngIf="f.numeroEntreprise.errors.required">
                Ce champ est obligatoire.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstname">Nom <b style="color: red">*</b></label>
            <input type="text" id="firstname" name="firstname" class="form-control" formControlName="nom"
              [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" />
            <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
              <div *ngIf="f.nom.errors.required">
                Ce champ est obligatoire.
              </div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="lastname">Prénom <b style="color: red">*</b></label>
            <input type="text" id="lastname" name="lastname" class="form-control" formControlName="prenom"
              [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" />
            <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
              <div *ngIf="f.prenom.errors.required">
                Ce champ est obligatoire.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="email">Email <b style="color: red">*</b></label>
            <input type="email" id="email" name="email" class="form-control" formControlName="email"
                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                   autocomplete="username" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">
                    Ce champ est obligatoire.
                </div>
                <div *ngIf="f.email.errors.email">
                    Cet e-mail est invalide.
                </div>
            </div>
        </div>
        
          <div class="form-group col-md-6">
            <label for="phone">Numéro de téléphone <b style="color: red">*</b></label>
            <ngx-intl-tel-input [cssClass]="'form-control input-custom-phone'" formControlName="tel"
              [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Belgium" [maxLength]="15"
              [phoneValidation]="true" name="tel"
              [ngClass]="{ 'is-invalid': submitted && f.tel.errors }"></ngx-intl-tel-input>
            <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
              <div *ngIf="f.tel.errors.required">Ce champ est obligatoire.</div>
            </div>

            <div *ngIf="
                  registerForm.value.tel &&
                  !f00.form.controls['tel'].invalid == false
                " style="color: red">
              Ce numéro de téléphone est invalide.
              <i class="bi bi-x"></i>
            </div>
            <div *ngIf="
                  registerForm.value.tel &&
                  !f00.form.controls['tel'].invalid == true
                " style="color: green">
              Ce numéro de téléphone est valide.
              <i class="bi bi-check"></i>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstname">Mot de passe <b style="color: red">*</b></label>
            <input type="password" id="password" name="password" class="form-control" formControlName="password"
                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="new-password" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">
                Ce champ est obligatoire.
              </div>
              <div *ngIf="f.password.errors.minlength">
                Le mot de passe doit contenir au moins 6 caractères
              </div>
              <div *ngIf="f.password.errors.maxlength">
                Le mot de passe doit contenir au maximum 50 caractères.
              </div>
            </div>
          </div>
          
          <div class="form-group col-md-6">
            <label for="lastname">Confirmation du mot de passe <b style="color: red">*</b></label>
            <input type="password" id="confirmationPassword" name="confirmationPassword" class="form-control"
              formControlName="confirmationPassword"
              [ngClass]="{ 'is-invalid': submitted && f.confirmationPassword.errors }"  autocomplete="new-password" />
            <div *ngIf="submitted && f.confirmationPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmationPassword.errors.required">
                Ce champ est obligatoire.
              </div>
              <div *ngIf="f.confirmationPassword.errors.minlength">
                Le mot de passe doit contenir au moins 6 caractères
              </div>
              <div *ngIf="f.confirmationPassword.errors.maxlength">
                Le mot de passe doit contenir au maximum 50 caractères.
              </div>
              <div *ngIf="f.confirmationPassword.errors.mustMatch">Les mots de passe ne correspondant pas. </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-group form-check">
            <input
              type="checkbox"
              formControlName="acceptTerms"
              class="form-check-input"
              [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"
            />
            <label for="acceptTerms" class="form-check-label"
              >J'ai lu et j'ai accepté les <a (click)="openPDF()" style="text-decoration: underline;cursor: pointer;color: #23b1a5;">conditions générales d'utilisation</a></label>
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
              L'acceptation des conditions générales d’utilisation est obligatoire
            </div>
          </div> -->
        <br>
        <div class="text-center">
          <button type="submit" class="btn btn-connexion">S'inscrire</button>
        </div>
      </form>
    </div>

  </div>
</header>
<app-footer></app-footer>
