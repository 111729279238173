<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="container px-4 px-lg-5 d-flex align-items-center justify-content-center">
        <div class="card col-lg-6 col-md-8 col-sm-10 p-5">
            <h2 class="text-center">Mot de passe oublié</h2>
            <br>
            <form>
                <br>
                <div class="form-group">
                    <label for="email">Email <b style="color: red">*</b></label>
                    <input type="email" class="form-control" id="email" placeholder="E-mail" />
                    <!-- <div *ngIf="submitted && f2.email.errors" class="invalid-feedback">
                        <div *ngIf="f2.email.errors.required">
                            Ce champ est obligatoire.
                        </div>
                        <div *ngIf="f2.email.errors.email">
                            Cet e-mail est invalide.
                        </div>
                    </div> -->
                </div>
                <br>
                <div class="text-center">
                    <button type="submit" class="btn btn-connexion">Envoyer</button>
                </div>
            </form>
        </div>
    </div>
</header>
<app-footer></app-footer>
