<section class="contact-section bg-black">
    <app-cookies></app-cookies>
    <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5">
            <!-- Partie avec le logo -->
            <div class="col-lg-4 col-md-12 mb-5 mb-lg-0">
                <img src="../../../assets/img/logo white.png" alt="Logo" style="max-width: 300px;">
            </div>
            <!-- Partie avec le plan du site (sitemap) -->
            <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
                <h4 class="text-white"><strong>Plan du site</strong></h4>
                <ul>
                    <li><a href="#accueil">Accueil</a></li>
                    <li><a href="#presentation">Présentation</a></li>
                    <li><a href="#portes-vers-la-sante">Produits</a></li>
                    <li><a href="#outlet">Outlet</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
                <h4 class="text-white"><strong>Nous suivre</strong></h4>
                <!-- <form [formGroup]="registerForm" class="d-flex justify-content-center">
                    <input type="email" name="email" placeholder="Votre adresse e-mail" class="form-control me-2" formControlName="email"
                     [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <button type="submit" (click)="onSubmit()" class=" btn-newsletter"><i class="fas fa-paper-plane"></i></button>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">
                            Veuillez renseigner ce champ.
                        </div>
                        <div *ngIf="f.email.errors.email">
                            Merci de saisir une adresse email valide.
                        </div>
                    </div>
                </form> -->
                <div class="social d-flex">
                    <a href="" target="_blank" class="mx-2"><i class="fab fa-facebook-f"></i></a>
                    <a href="" target="_blank" class="mx-2"><i class="fab fa-instagram"></i></a>
                    <a href="" target="_blank" target="_blank" class="mx-2"><i class="fab fa-linkedin"></i></a>
                </div>
            </div>
        </div>
    </div>
    <br>
</section>

<footer class="footer small text-center text-white-50">
    <div class="container px-4 px-lg-5" style="color: white;">
        <a href="" target="_blank">Conditions générales d’utilisation</a> | &copy; Tous droits réservés {{year}} | Conçu et développé par
        <a href="https://www.voguelconsulting.com/" target="_blank" >Voguel consulting</a>
    </div>
</footer>



