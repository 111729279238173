<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="slideshow">
        <img src="../../../assets/img/DSC_0925.webp" alt="Image 1">
        <img src="../../../assets/img/DSC_0915.webp" alt="Image 2">
        <img src="../../../assets/img/DSC_0942.webp" alt="Image 3">
    </div>
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <h1 class="mx-auto my-0">RUBAN FRONCEUR</h1>
                <h2 class="text-white-50 mx-auto mt-2 mb-5 text-shadow" style="color: white !important">
                   Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur similique quia incidunt, illum vero dolorum porro quis? Animi recusandae, nemo, neque iste veniam illum ex corrupti dignissimos voluptates, quam sunt.
                </h2>
                <!-- <a class="btn btn-connexion" data-toggle="modal" data-target="#exampleModalArchiver"
                     style="color: #fff">Demander une démo</a> -->
            </div>
        </div>
    </div>
</header>

<section class="projects-section bg-light" id="presentation">
    <div style="margin-bottom: 3rem;">
        <h1 class="text-center" style="font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; font-size: 3rem; line-height: 2.5rem; letter-spacing: 0.5rem; color:#e6b31e ;">Présentation</h1>
        <hr class="text-primary custom-line" style="width: 10%; margin: auto; margin-top: 1.2rem;">
    </div>
    <div class="container">
        <!-- Première section -->
        <div class="row">
            <div class="col-md-6">
                <img src="../../../assets/img/DSC_0925.webp" alt="Image 1" class="img-fluid mb-4">
            </div>
            <div class="col-md-6 align-self-center">
                <p  class="ml-5" style="color: #fff;">Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin.</p>
            </div>
        </div>

        <!-- Deuxième section -->
        <div class="row mt-5">
            <div class="col-md-6 order-md-2">
                <img src="../../../assets/img/DSC_0936.webp" alt="Image 2" class="img-fluid mb-4">
            </div>
            <div class="col-md-6 order-md-1 align-self-center">
                <p class="mr-5" style="color: #fff;">Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin.</p>
            </div>
        </div>
    </div>
</section>




<section class="projects-section bg-light" id="portes-vers-la-sante">
    <div style="margin-bottom: 3rem;">
        <h1 class="text-center" style="font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; font-size: 3rem; line-height: 2.5rem; letter-spacing: 0.5rem; color:#e6b31e ;">Nos derniers produits</h1>
        <hr class="text-primary custom-line" style="width: 10%; margin: auto; margin-top: 1.2rem;">
    </div>
    <div class="container">
        <div class="row ">
            <!-- Produit -->
            <div *ngFor="let prod of products" class="col-md-3" (click)="goToDetails(prod._id,prod.nom)">
                <div class="card mr-2" style="height: 300px;">
                    <div class="card-img-container" style="height: 100%; overflow: hidden;">
                        <img src="{{pathFile}}{{prod.image}}" class="card-img-top" style="object-fit: cover; height: 100%;" alt="Nom du produit">
                        <div class="overlay">
                            <div class="overlay-content">
                                <h5 class="overlay-text">{{prod.nom}}</h5>
                                <div *ngIf="authService.loggedIn() && prod.tarifpromo != ''">
                                    <p class="overlay-text">{{prod.tarifpromo}}&euro;</p>
                                    <p class="overlay-text"><del>{{prod.tarifregulier}}&euro;</del></p>
                                </div>
                                <div >
                                    <p *ngIf="authService.loggedIn() && prod.tarifpromo == ''" class="overlay-text">{{prod.tarifregulier}}&euro;</p>
                                </div>
                                <p *ngIf="prod.etat == 'En stock'" class="stock-text">En stock</p>
                                <p *ngIf="prod.etat != 'En stock'" class="out-stock-text">En rupture du stock</p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="badge-container">
                        <div class="badge mt-2 ml-2">{{prod.categories}}</div>
                        <div *ngIf="authService.loggedIn() && prod.tarifpromo != ''" class="badge mt-2  badge2" style="background-color:#00c268 ;">En promo {{prod.promo}}%</div>
                    </div>
                </div>
            </div>
            <div class="container p-5" *ngIf="products && products.length === 0">
                <div class="col-md-12 pt-5 text-center">
                    <h3 style="color: #e6b31e;">Aucun produit trouvé</h3>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="projects-section bg-light" id="outlet">
    <div style="margin-bottom: 3rem;">
        <h1 class="text-center" style="font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; font-size: 3rem; line-height: 2.5rem; letter-spacing: 0.5rem; color:#e6b31e ;">Outlet</h1>
        <hr class="text-primary custom-line" style="width: 10%; margin: auto; margin-top: 1.2rem;">
    </div>
    <div class="container">
        <div class="row">
            <!-- Produits -->
            <div *ngFor="let prod of displayedProducts" class="col-md-4" (click)="goToDetails(prod._id,prod.nom)">
                <div class="card mr-2" style="height: 300px;">
                    <div class="card-img-container" style="height: 100%; overflow: hidden;">
                        <img src="{{pathFile}}{{prod.image}}" class="card-img-top" style="object-fit: cover; height: 100%;" alt="Nom du produit">
                        <div class="overlay">
                            <div class="overlay-content">
                                <h5 class="overlay-text">{{prod.nom}}</h5>
                                <div *ngIf="authService.loggedIn() && prod.tarifpromo != ''">
                                    <p class="overlay-text">{{prod.tarifpromo}}&euro;</p>
                                    <p class="overlay-text"><del>{{prod.tarifregulier}}&euro;</del></p>
                                </div>
                                <div>
                                    <p *ngIf="authService.loggedIn() && prod.tarifpromo == ''" class="overlay-text">{{prod.tarifregulier}}&euro;</p>
                                </div>
                                <p *ngIf="prod.etat == 'En stock'" class="stock-text">En stock</p>
                                <p *ngIf="prod.etat != 'En stock'" class="out-stock-text">En rupture du stock</p>
                            </div>
                        </div>
                    </div>
                    <div class="badge-container">
                        <div class="badge mt-2 ml-2">{{prod.categories}}</div>
                        <div *ngIf="authService.loggedIn() && prod.tarifpromo != ''" class="badge mt-2  badge2" style="background-color:#00c268 ;">En promo {{prod.promo}}%</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Bouton Voir plus -->
        <div class="text-center mt-4">
            <button class="btn btn-primary" *ngIf="displayedProducts && copyslistProducts && displayedProducts.length < copyslistProducts.length" (click)="loadMoreProducts()">Voir plus</button>
            <button class="btn btn-primary" *ngIf="displayedProducts && copyslistProducts && displayedProducts.length == copyslistProducts.length" (click)="loadLessProducts()">Voir Moins</button>
        </div>    

        <!-- Aucun produit trouvé -->
        <!-- <div class="container p-5" *ngIf="products && products.length === 0">
            <div class="col-md-12 pt-5 text-center">
                <h3 style="color: #e6b31e;">Aucun produit trouvé</h3>
            </div>
        </div> -->
    </div>
</section>        


<section class="projects-section bg-light" id="contact">
    <div style="margin-bottom: 3rem;">
        <h1 class="text-center" style="font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; font-size: 3rem; line-height: 2.5rem; letter-spacing: 0.5rem; color:#e6b31e ;">Contactez-nous</h1>
        <hr class="text-primary custom-line" style="width: 10%; margin: auto; margin-top: 1.2rem;">
    </div>
    <div class="container px-4 px-lg-5" style="padding: 3rem;">
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div class="col-md-6">
                <!-- Bloc d'informations de contact -->
                <div class="contact-info">
                    <h2 style="color: #e6b31e;">Informations de contact</h2>
                    <br>
                    <p><i class="fa fa-envelope" style="color: #e6b31e;"></i><a style="color: #cacaca !important; font-size: 1rem !important; text-decoration: none !important;" href="mailto:vianney.legrand@gmail.com">  vianney.legrand@gmail.com</a></p>
                    <p><i class="fa fa-phone" style="color: #e6b31e;"></i><a style="color: #cacaca !important; font-size: 1rem !important; text-decoration: none !important;" href="tel:+1234567890">  +32 468 38 89 43</a></p>
                    <p><i class="fa-solid fa-location-dot" style="color: #e6b31e;"></i><a style="color: #cacaca !important; font-size: 1rem !important; text-decoration: none !important;" href="tel:+1234567890">  6/3 Rue de neuve,1000 Bruxelles</a></p>
                    <br>
                    <h2 style="color: #e6b31e;">Horaires de Travail</h2>
                    <br>
                    <p><a style="color: #cacaca !important; font-size: 1rem !important; text-decoration: none !important;" href="tel:+1234567890">  Lundi - Dimanche</a></p>
                    <p><a style="color: #cacaca !important; font-size: 1rem !important; text-decoration: none !important;" href="tel:+1234567890">  09:00 - 18:00</a></p>
                </div>
            </div>
            <div class="col-md-6">
                <!-- Bloc de formulaire de contact -->
                <form #f0="ngForm" [formGroup]="registerForm2">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="nom"><strong style="color: #cacaca; font-size: 1.2rem;">Nom <b style="color: red">*</b></strong></label>
                            <input type="text" class="form-control" id="nom" placeholder="Nom" formControlName="nom" [ngClass]="{ 'is-invalid': submitted2 && f2.nom.errors }" />
                            <div *ngIf="submitted2 && f2.nom.errors" class="invalid-feedback">
                                <div *ngIf="f2.nom.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="prenom"><strong style="color: #cacaca; font-size: 1.2rem;">Prénom <b style="color: red">*</b></strong></label>
                            <input type="text" class="form-control" id="prenom" placeholder="Prénom" formControlName="prenom" [ngClass]="{ 'is-invalid': submitted2 && f2.prenom.errors }" />
                            <div *ngIf="submitted2 && f2.prenom.errors" class="invalid-feedback">
                                <div *ngIf="f2.prenom.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email"><strong style="color: #cacaca; font-size: 1.2rem;">Email <b style="color: red">*</b></strong></label>
                        <input type="email" class="form-control" id="email" placeholder="E-mail" formControlName="email" [ngClass]="{ 'is-invalid': submitted2 && f2.email.errors }" />
                        <div *ngIf="submitted2 && f2.email.errors" class="invalid-feedback">
                            <div *ngIf="f2.email.errors.required">
                                Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f2.email.errors.email">
                                Cet e-mail est invalide.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="telephone"><strong style="color: #cacaca; font-size: 1.2rem;">Numéro de téléphone <b style="color: red">*</b></strong></label>
                        <br />
                        <ngx-intl-tel-input [cssClass]="'form-control input-custom-phone'" formControlName="telephone" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true" 
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Belgium" [maxLength]="15" [phoneValidation]="true" name="telephone" [ngClass]="{ 'is-invalid': submitted2 && f2.telephone.errors }"></ngx-intl-tel-input>
                        <div *ngIf="submitted2 && f2.telephone.errors" class="invalid-feedback">
                            <div *ngIf="f2.telephone.errors.required">Ce champ est obligatoire.</div>
                        </div>
                        <div *ngIf="registerForm2.value.telephone && !f0.form.controls['telephone'].invalid == false" style="color: red">
                            Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
                        </div>
                        <div *ngIf="registerForm2.value.telephone && !f0.form.controls['telephone'].invalid == true" style="color: green">
                            Ce numéro de téléphone est valide. <i class="bi bi-check"></i>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message"><strong style="color: #cacaca; font-size: 1.2rem;">Message <b style="color: red">*</b></strong></label>
                        <textarea class="form-control" id="message" rows="3" placeholder="Votre message" formControlName="message" [ngClass]="{ 'is-invalid': submitted2 && f2.message.errors }"></textarea>
                        <div *ngIf="submitted2 && f2.message.errors" class="invalid-feedback">
                            <div *ngIf="f2.message.errors.required">
                                Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f2.message.errors.maxlength">
                                Ce champ peut comporter au maximum 200 caractères.
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <button class="btn-connexion" (click)="onSubmit2()">Envoyer</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>

<!--modal-->
<!-- <div class="modal fade" id="exampleModalArchiver" #exampleModalArchiver tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel" style="color: #e6b31e">
                    Demander une démo
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #f00="ngForm" [formGroup]="registerForm">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="nom">Nom <b style="color: red">*</b></label>
                            <input type="text" class="form-control" id="nom" placeholder="Nom" formControlName="nom"
                                [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" />
                            <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                                <div *ngIf="f.nom.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="prenom">Prénom <b style="color: red">*</b></label>
                            <input type="text" class="form-control" id="prenom" placeholder="Prénom"
                                formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" />
                            <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                                <div *ngIf="f.prenom.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Email <b style="color: red">*</b></label>
                        <input type="email" class="form-control" id="email" placeholder="E-mail" formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">
                                Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f.email.errors.email">
                                Cet e-mail est invalide.
                            </div>
                        </div>
                    </div>
                        <div class="form-group">
                            <label>Êtes vous un médecin ? <b style="color: red;"> * </b></label>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                  <input
                                    type="radio"
                                    formControlName="typeUser"
                                    [value]="true"
                                  />
                                  Oui
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                  <input
                                    type="radio"
                                    formControlName="typeUser"
                                    [value]="false"
                                  />
                                  Non
                                </div>
                              </div>
                  
                        </div>
                        <div *ngIf="registerForm.value.typeUser == true" class="form-group">
                            <label >Quel est votre spécialité? <b style="color: red">*</b></label>
                            <ng-select
                            style="height: 50px;"
                            [items]="specialiites"
                            class="form-control input-custom"
                            formControlName="specialite"
                            (change)="selectSpecilaite($event)"
                            [(ngModel)]="selectedSpecialite"
                            [closeOnSelect]="true"
                             >
                          </ng-select>
                        </div>
                    <div class="form-group">
                        <label for="telephone">Numéro de téléphone <b style="color: red">*</b></label>
                        <br />
                        <ngx-intl-tel-input [cssClass]="'form-control input-custom-phone'" formControlName="telephone"
                            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Tunisia" [maxLength]="15"
                            [phoneValidation]="true" name="telephone"
                            [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }"></ngx-intl-tel-input>
                        <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
                            <div *ngIf="f.telephone.errors.required">Ce champ est obligatoire.</div>
                        </div>

                        <div *ngIf="
                registerForm.value.telephone &&
                !f00.form.controls['telephone'].invalid == false
              " style="color: red">
              Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
                        </div>
                        <div *ngIf="
                registerForm.value.telephone &&
                !f00.form.controls['telephone'].invalid == true
              " style="color: green">
              Ce numéro de téléphone est valide. <i class="bi bi-check"></i>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message">Votre demande <b style="color: red">*</b></label>
                        <textarea class="form-control" id="message" rows="3" placeholder="Votre demande"
                            formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
            </textarea>
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">
                                Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f.message.errors.maxlength">
                                Ce champ peut comporter au maximum 200 caractères.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer text-center">
                <button class="btn btn-add" (click)="onSubmit()">Envoyer</button>
            </div>
        </div>
    </div>
</div> -->