import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private _productURL=`${environment.baseUrl}/produit`

  constructor(private http: HttpClient) { }

     getproducts() :Observable <any> {
      return this.http.get(`${this._productURL}`);
      }

      getproductsdisplayed() :Observable <any> {
        return this.http.get(`${this._productURL}/all/allowed/produits`);
        }

        getListProductsStorm() :Observable <any> {
          return this.http.get(`${this._productURL}/storm/Products/all`);
          }

          getDetailsProduct(id : any):Observable<any>
          {
            return this.http.get(`${this._productURL}/getDetailsProductById-Name/${id}`);
        
          } 
        // qui ont une promotion
          getproductsOutlet() :Observable <any> {
            return this.http.get(`${this._productURL}/storm/Products/outlet`);
            }


}
