import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators } from '@angular/forms';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { NotificationService } from 'src/app/services/notification.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css']
})
export class InscriptionComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  country='BE'
  countryCode="32";
  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  notification;

  constructor(private formBuilder: FormBuilder
    ,private utilisateurService: UtilisateurService
    ,private notificationService: NotificationService
   ,public iziToast: Ng2IzitoastService
   ,private router: Router

  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      societe: ['', Validators.required],
      numeroEntreprise: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(50)]],
      confirmationPassword: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(50)]],
      tel: ['', Validators.required],    },
    {
      validator: MustMatch('password', 'confirmationPassword')
    }
    );
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
    this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber
    this.notification = {
      titre: 'Inscription d\'un nouveau client',
      texte: this.registerForm.value.nom + ' ' + this.registerForm.value.prenom + ' vient de s\'inscrire',
      lien: 'utilisateur',
      notifAdmin: true
    }

    this.utilisateurService.verifyEmail(this.registerForm.value).subscribe(data => {
      console.log("data.",data["success"])
      if (data["success"] == true) {
        this.iziToast.show({
          message: 'Cet email est déjà utilisé',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          // overlay: true,
          // overlayClose: true,
        });
        return false;
      }
      else {
        this.utilisateurService.inscriptionUtilisateur(this.registerForm.value).subscribe(utilisateur => {
          this.notificationService.saveNotification(this.notification).subscribe(data => {
            this.iziToast.show({
              message:'Vous êtes bien inscrit !',
              messageColor:'#21726B',
              progressBarColor:'#21726B',
              imageWidth:45,
              position:'topRight',
              timeout:5000,
              backgroundColor:'#e7f5f5',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
               })
               this.router.navigate(['/connexion' ])

          });
        });
      }
    })
  }

  ngAfterViewInit(): void {
    // Faire défiler la fenêtre vers le haut au chargement du composant
    window.scrollTo(0, 0);
  }

}
