<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="slideshow">
        <img src="../../../assets/img/DSC_0925.webp" alt="Image 1">
        <img src="../../../assets/img/DSC_0915.webp" alt="Image 2">
        <img src="../../../assets/img/DSC_0942.webp" alt="Image 3">
    </div>
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <h1 class="mx-auto my-0">Découvrez nos produits!</h1>
            </div>
        </div>
    </div>
</header>
<section class="projects-section bg-light" id="products" style="padding: 0 !important;">
    <div class="p-5">
        <div class="row">
            <!-- Barre latérale des filtres -->
            <div class="col-lg-3 col-md-3 mb-4">
                <!-- Placez vos filtres ici -->
                <div class="filters">
                    <div class="d-md-flex justify-content-between align-items-center mb-3">
                        <h3 style="color: #e6b31e;">Filtres</h3>
                        <button type="button" class="btn btn-connexion mb-3 mb-md-0" (click)="resetFilters()"><i class="fa-solid fa-arrows-rotate"></i></button>
                    </div>                   
                    <!-- Filtres -->
                    <div *ngIf="authService.loggedIn()" class="filter">
                        <form oninput="result.value = slider.value">
                            <h4 style="color: #e6b31e ;">Prix</h4>
                           <output name="result" for="slider">{{moy}}</output>€
                           <br>
                           <input type="range" id="slider" class="slider ng-valid ng-dirty ng-touched"
                           (change)="filter()"  [(ngModel)]="priceSelected" name="priceSelected"
                           min="{{ minPrice }}" max="{{ maxPrice }}" step="1" value="{{ moy }}">
     
                          </form>
                    </div>
                    <br>
                    <div class="filter">
                        <h4 style="color: #e6b31e ;" (click)="toggleCategoryFilter()">Catégories <span class="arrow-icon">{{ isCategoryFilterOpen ? '▼' : '►' }}</span></h4>
                        <ul [hidden]="!isCategoryFilterOpen">
                            <li *ngFor="let cat of categories">
                              <input type="checkbox" id="{{cat}}" [(ngModel)]="selectedCategories[cat]" (change)="filter()">
                              <label for="{{cat}}">{{cat}}</label>
                            </li>
                          </ul>
                    </div>
                    <div class="filter">
                        <h4 style="color: #e6b31e ;" (click)="toggleConditionnementFilter()" >Conditionnement <span class="arrow-icon">{{ isCategoryFilterOpen ? '▼' : '►' }}</span></h4>
                        <ul [hidden]="!isConditionnementFilterOpen">
                            <li  *ngFor="let con of conditionings">
                                <input type="checkbox" id="{{con}}" [(ngModel)]="selectedConditionings[con]" (change)="filter()">
                                <label for="{{con}}">{{con}}</label>
                            </li>
                        </ul>
                    </div>
                    <div class="filter">
                        <h4 style="color: #e6b31e ;" (click)="toggleColorFilter()">Couleurs <span class="arrow-icon">{{ isCategoryFilterOpen ? '▼' : '►' }}</span></h4>
                        <ul [hidden]="!isColorFilterOpen">
                            <li  *ngFor="let col of colors">
                                <input type="checkbox" id="{{col}}" [(ngModel)]="selectedColors[col]" (change)="filter()">
                                <label for="{{col}}">{{col}}</label>
                            </li>
                        </ul>
                    </div>
                    <div class="filter">
                        <h4 style="color: #e6b31e ;" (click)="toggleCordNbrFilter()">Nombre de corde <span class="arrow-icon">{{ isCategoryFilterOpen ? '▼' : '►' }}</span></h4>
                        <ul [hidden]="!isCordNbrFilterOpen">
                            <li  *ngFor="let rop of ropes">
                                <input type="checkbox" id="{{rop}}" [(ngModel)]="selectedRopes[rop]" (change)="filter()">
                                <label for="{{rop}}">{{rop}}</label>
                            </li>
                        </ul>
                    </div>
                    <div class="filter">
                        <h4 style="color: #e6b31e ;" (click)="togglePocheFilter()">Poche <span class="arrow-icon">{{ isCategoryFilterOpen ? '▼' : '►' }}</span></h4>
                        <ul [hidden]="!isPocheFilterOpen">
                            <li  *ngFor="let po of poches">
                                <input type="checkbox" id="{{po}}" [(ngModel)]="selectedPockets[po]" (change)="filter()">
                                <label for="{{po}}">{{po}}</label>
                            </li>
                        </ul>
                    </div>
                    <div class="filter">
                        <h4 style="color: #e6b31e ;" (click)="toggleRatioFilter()">Ratio <span class="arrow-icon">{{ isCategoryFilterOpen ? '▼' : '►' }}</span></h4>
                        <ul [hidden]="!isRatioFilterOpen">
                            <li  *ngFor="let rat of ratipos">
                                <input type="checkbox" id="{{rat}}" [(ngModel)]="selectedRatios[rat]" (change)="filter()">
                                <label for="{{rat}}">{{rat}}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Liste des produits -->
            <div class="col-lg-9 col-md-9">
                <div class="row ">
                    <!-- Produit -->
                    <div *ngFor="let prod of products | paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };let k =index" class="col-md-4" (click)="goToDetails(prod._id,prod.nom)">
                        <div class="card mr-2" style="height: 300px;">
                            <div class="card-img-container" style="height: 100%; overflow: hidden;">
                                <img src="{{pathFile}}{{prod.image}}" class="card-img-top" style="object-fit: cover; height: 100%;" alt="Nom du produit">
                                <div class="overlay">
                                    <div class="overlay-content">
                                        <h5 class="overlay-text">{{prod.nom}}</h5>
                                        <div *ngIf="authService.loggedIn() && prod.tarifpromo != ''">
                                            <p class="overlay-text">{{prod.tarifpromo}}&euro;</p>
                                            <p class="overlay-text"><del>{{prod.tarifregulier}}&euro;</del></p>
                                        </div>
                                        <div >
                                            <p *ngIf="authService.loggedIn() && prod.tarifpromo == ''" class="overlay-text">{{prod.tarifregulier}}&euro;</p>
                                        </div>
                                        <p *ngIf="prod.etat == 'En stock'" class="stock-text">En stock</p>
                                        <p *ngIf="prod.etat != 'En stock'" class="out-stock-text">En rupture du stock</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="badge-container">
                                <div class="badge mt-2 ml-2">{{prod.categories}}</div>
                                <div *ngIf="authService.loggedIn() && prod.tarifpromo != ''" class="badge mt-2 ml-2 badge2" style="background-color:#00c268 ;">Promo {{prod.promo}}%</div>
                            </div>
                        </div>
                    </div>
                    <div class="container p-5" *ngIf="products && products.length === 0">
                        <div class="col-md-12 pt-5 text-center">
                            <h3 style="color: #e6b31e;">Aucun produit trouvé</h3>
                        </div>
                    </div>
                </div>
                <div  class="card-footer border-0 py-5"  *ngIf="products && products.length > 12">
                    <div class="d-flex justify-content-center">
                      <pagination-controls
                        class="my-pagination"
                        previousLabel="Précédent"
                        nextLabel="Suivant"
                        (pageChange)="onTableDataChange($event)"
                      >
                      </pagination-controls>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>


