import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProductService } from 'src/app/services/product.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  products: any;
  pathFile = environment.uploadFileRoot ;
  categories:any;
  categoriesChoisesInFilter = []
  copyListProducts: any;
  selectedCategory :any
  selectedCategories: { [key: string]: boolean } = {};
  selectedConditionings :{ [key: string]: boolean } = {};
  selectedColors: { [key: string]: boolean } = {};
  conditionings: any;
  colors: any;
  selectedRopes:{ [key: string]: boolean } = {};
  selectedPockets: { [key: string]: boolean } = {};
  selectedRatios: { [key: string]: boolean } = {};
  priceSelected
  ropes: any
  ratipos: any
  poches: any
  maxPrice :any
  minPrice: any;
  moy: any;


  page: number = 1;
  count: number = 0;
  tableSize: number = 12;
  tableSizes: any = [3, 6, 9, 12];


  authService: AuthService;

  isCategoryFilterOpen = false;
  isConditionnementFilterOpen = false;
  isColorFilterOpen = false;
  isCordNbrFilterOpen = false;
  isPocheFilterOpen = false;
  isRatioFilterOpen = false;
 
  constructor(private productService :ProductService, private authServiceInstance: AuthService
    ,private router: Router,
    private sharedService :SharedService) {
    this.authService = authServiceInstance;
   }

  ngOnInit(): void {
    this.getProducts()
    
  }

  getProducts() {
    this.productService.getListProductsStorm().subscribe((e) => {
      this.products = e
      this.copyListProducts = this.products;
      this.categories =Array.from(new Set(this.products.map(item => item.categories)));
      this.conditionings =Array.from(new Set(this.products.map(item => item.conditionnement)));
      this.colors =Array.from(new Set(this.products.map(item => item.couleur)));
      this.ropes =Array.from(new Set(this.products.map(item => item.nbreCorde)));
      this.poches =Array.from(new Set(this.products.map(item => item.nbrePoche)));
      this.ratipos =Array.from(new Set(this.products.map(item => item.ratio)));
      console.log("categories",this.categories)

// la valeur maximale du tarif regulaire
      const tarifsNumeriques = this.products.map(produit => parseFloat(produit.tarifregulier)).filter(tarif => !isNaN(tarif))
      const maxTarif = Math.max(...tarifsNumeriques);
      const produitMaxTarif = this.products.find(produit => parseFloat(produit.tarifregulier) === maxTarif);
      this.maxPrice = produitMaxTarif.tarifregulier

  // la valeur minimale du tarif régulier
      const minTarif = Math.min(...tarifsNumeriques);
      const produitMinTarif = this.products.find(produit => parseFloat(produit.tarifregulier) === minTarif);
      this.minPrice = produitMinTarif.tarifregulier;

      // la valeur moyenne du tarif regulaire de toutes les produits
      this.moy = (parseFloat(this.maxPrice) + parseFloat(this.minPrice)) / 2;
      this.moy = this.moy.toString()
      console.log("this.moy",this.moy)


    });

  
}
filter() {
  this.products = this.copyListProducts;
  let filteredProducts = [...this.copyListProducts];

  // Filtrage par catégories
  let selectedCats = Object.keys(this.selectedCategories).filter(cat => this.selectedCategories[cat]);
  if (selectedCats.length !== 0) {
    filteredProducts = filteredProducts.filter(product => selectedCats.includes(product.categories));
  }

  // Filtrage par conditionnements
  let selectedCons = Object.keys(this.selectedConditionings).filter(con => this.selectedConditionings[con]);
  if (selectedCons.length !== 0) {
    filteredProducts = filteredProducts.filter(product => selectedCons.includes(product.conditionnement));
  }

  // Filtrage par couleurs
  let selectedColors = Object.keys(this.selectedColors).filter(col => this.selectedColors[col]);
  if (selectedColors.length !== 0) {
    filteredProducts = filteredProducts.filter(product => selectedColors.includes(product.couleur));
  }

  // Filtrage par nombre de corde
  let selectedRopes = Object.keys(this.selectedRopes).filter(rope => this.selectedRopes[rope]);
  if (selectedRopes.length !== 0) {
    filteredProducts = filteredProducts.filter(product => selectedRopes.includes(product.nbreCorde.toString()));
  }

  // Filtrage par poche
  let selectedPockets = Object.keys(this.selectedPockets).filter(pocket => this.selectedPockets[pocket]);
  if (selectedPockets.length !== 0) {
    filteredProducts = filteredProducts.filter(product => selectedPockets.includes(product.nbrePoche.toString()));
  }

  // Filtrage par ratio
  let selectedRatios = Object.keys(this.selectedRatios).filter(ratio => this.selectedRatios[ratio]);
  if (selectedRatios.length !== 0) {
    filteredProducts = filteredProducts.filter(product => selectedRatios.includes(product.ratio.toString()));
  }

   // Filtrage par prix
   const minPrice = parseInt(this.minPrice, 10);
   const priceSelected = parseInt(this.priceSelected, 10);


   if(this.priceSelected)
   filteredProducts = filteredProducts.filter(product => {
     const tarifRegulier = parseInt(product.tarifregulier, 10);
     return tarifRegulier >= minPrice && tarifRegulier <= priceSelected;
   });

  // Mise à jour des produits filtrés
  this.products = filteredProducts;
}


// filter2() {
//   console.log("value", this.priceSelected);
//   this.products = this.copyListProducts;
//   let filteredProducts = [...this.copyListProducts];

//   const minPrice = parseInt(this.minPrice, 10);
//   const priceSelected = parseInt(this.priceSelected, 10);

//   filteredProducts = filteredProducts.filter(produit => {
//     const tarifRegulier = parseInt(produit.tarifregulier, 10);
//     return tarifRegulier >= minPrice && tarifRegulier <= priceSelected;
//   });

//   console.log("filteredProducts", filteredProducts);
//   this.products = filteredProducts
// }


onTableDataChange(event: any) {
  this.page = event;
  this.products=this.products

}
onTableSizeChange(event: any): void {
  this.tableSize = event.target.value;
  this.page = 1;
  this.getProducts()
}
  
resetFilters() {
  this.selectedCategories = {};
  this.selectedConditionings = {};
  this.selectedColors = {};
  this.selectedRopes = {};
  this.selectedPockets = {};
  this.selectedRatios = {};
  this.priceSelected = null;
  this.filter();
}
goToDetails(id,nom){
  this.router.navigate(["detail-produit"]);
  this.sharedService.saveInfoProduct(id,nom)
}

toggleCategoryFilter() {
  this.isCategoryFilterOpen = !this.isCategoryFilterOpen;
}
toggleConditionnementFilter(){
  this.isConditionnementFilterOpen = !this.isConditionnementFilterOpen;
}
toggleColorFilter(){
  this.isColorFilterOpen = !this.isColorFilterOpen;
}
toggleCordNbrFilter(){
  this.isCordNbrFilterOpen = !this.isCordNbrFilterOpen;
}
togglePocheFilter(){
  this.isPocheFilterOpen = !this.isPocheFilterOpen;
}
toggleRatioFilter(){
  this.isRatioFilterOpen = !this.isRatioFilterOpen;
}




}